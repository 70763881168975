import app from "js/legacy-app";

app.service('AuthService',
    function ($location, User, Notification, $http, $timeout, $window, NotificationWSService,
              $rootScope, $cookies, $requestStorage, ErrorHandler, $q, $wsService, $localStorage, RecaptchaService
    ) {
        'ngInject';

        let user = null;

        let currentUser = function (callback, reload) {
            let token = getToken();

            if ((token && !user) || reload) {
                updateHttp(token);
                User.current(function (data) {
                    user = data;
                    setSentryUserContext(user);
                    if (callback) {
                        $timeout(function () {
                            callback(data);
                        });
                    }
                }, function (error) {
                    deleteToken();
                    deleteAdminToken();
                    user = null;
                    setSentryUserContext(user);
                    Notification.error('Your session has expired. Please log in again');
                });
            } else {
                if (callback) {
                    $timeout(function () {
                        callback(user);
                    });
                }
            }
        };

        const setSentryUserContext = function (user) {
            try {
                if (user) {
                    // TODO need to pay
                    /*Sentry.setUser({
                        id: user.id,
                        email: user.email,
                        name: user.name + " " + user.surname
                    });*/
                }/* else {
                    Sentry.configureScope(scope => scope.clear())
                }*/
            } catch (e) {
                console.error(e.stack);
            }
        };

        let authorize = function (code, redirectLink, authorization, callback) {
            if (!code) {
                Notification.warning('Unable to login user');
            } else {
                var data = new FormData();
                data.append('code', code);
                var config = {
                    headers: {
                        'Content-Type': undefined,
                        'Authorization': authorization
                    }
                };
                $http.post('/api/v1/oauth2/token?scope=back_office&grant_type=exchange_code', data, config)
                    .success((token) => {
                        token.authorization = authorization;
                        setToken(token);
                        currentUser(function (user) {
                            if (user.role === 'PARTIALLY_REGISTERED') {
                                $location.path('/complete-social-registration');
                            } else if (redirectLink) {
                                $location.url(redirectLink);
                            } else {
                                if (user.role === 'USER') {
                                    if (isActivatedUser()) {
                                        $location.path('/bicycle-owner/service-bookings');
                                    } else {
                                        $location.path('/bicycle-owner/complete-registration');
                                    }
                                } else {
                                    NotificationWSService.connect(getToken());
                                    $location.path('/dashboard');
                                }
                            }

                            if (callback) {
                                callback()
                            }
                        });
                    })
                    .error(() => {
                        Notification.warning('Unable to login user');
                        restoreAndDeleteBackupToken()
                    });
            }
        };

        let authorizeUser = (code, redirectLink, callback) => {
            let authorization = 'Basic ' + process.env.USER_AUTH_CREDENTIALS;
            authorize(code, redirectLink, authorization, callback)
        }

        let authorizeAsUser = (code, redirectLink) => {
            let authorization = 'Basic ' + process.env.ADMIN_AUTH_CREDENTIALS;
            authorize(code, redirectLink, authorization)
        }

        let activation = function (activationToken, successCallback, errorCallback) {
            User.confirmRegistration({activationToken: activationToken}, {},
                function (response) {
                    authorizeUser(response.token, null, (data) => {
                        if (successCallback) {
                            successCallback(data);
                        }
                    });
                }, function (response) {
                    if (response.data.messages) {
                        Notification.error(response.data.messages[0]);
                    }
                    if (errorCallback) {
                        errorCallback(response);
                    }
                    $location.path('/dashboard');
                });
        };

        let activateBusinessAccount = function (activateAccount, activationToken, psswd, redirect) {
            activateAccount({token: activationToken}, psswd, function (response) {
                authorizeUser(response.token, redirect, (data) => {
                    Notification.success('Your account is activated now');
                });
            }, function (response) {
                if (response.data.messages) {
                    Notification.error(response.data.messages[0]);
                    $location.path("/home").search({});
                } else if (response.data.field_errors) {
                    Notification.error(response.data.field_errors[0].message);
                }
            });
        };

        let cancelActivation = function (cancelRegistration, cancelToken, successCallback, errorCallback) {
            cancelRegistration({token: cancelToken}, {}, function () {
                Notification.success('Account activation canceled');
                $location.search({});
                $location.path('/home');
                if (successCallback) {
                    successCallback();
                }
            }, function (response) {
                $location.search({});
                $location.path('/home');
                if (response.data.field_errors) {
                    Notification.error(response.data.field_errors[0].message);
                }
                if (errorCallback) {
                    errorCallback(response);
                }
            });
            let token = getToken();
            if (token !== null) {
                deleteToken();
            }
        };

        let sendActivationEmail = function (email) {
            User.save({id: "current/activation"}, {}, function () {
                $location.path('/complete-creation-request').search({email: email});
            }, function (error) {
                if (error.data.messages) {
                    Notification.error(error.data.messages[0]);
                }
            });
        };

        let sendActivationEmailWhileRequestCreation = function (email) {
            sendActivationEmail(email);
        };

        let login = function (email, password, redirect, callback) {
            var authorization = 'Basic ' + process.env.USER_AUTH_CREDENTIALS;
            var data = new FormData();
            data.append('username', email);
            data.append('password', password);
            authorisation(data, 'default_password', authorization).success((data, status, headers, config) => {
                data.authorization = authorization;
                setToken(data);
                if (callback) {
                    callback();
                }
                currentUser(function (user) {
                    if (redirect) {
                        if (isAbsoluteUrl(redirect)) {
                            window.location = redirect;
                        } else {
                            $location.url(redirect);
                        }
                    } else if (isPartiallyRegistered()) {
                        $location.path('/complete-social-registration');
                    } else {
                        if (isUser()) {
                            if (isActivatedUser()) {
                                $location.path('/bicycle-owner/service-bookings');
                            } else {
                                $location.path('/bicycle-owner/complete-registration');
                            }
                        } else {
                            NotificationWSService.connect(getToken());
                            $location.path('/dashboard');
                        }
                    }
                }, true);
            }).error(function (response, status) {
                ErrorHandler.onError(response, 'Incorrect email or password');
                if (callback) {
                    callback();
                }
            });
        };

        let refreshToken = function (successCallback, errorCallback) {
            var token = getToken();
            var authorization = token.authorization ? token.authorization : 'Basic ' + process.env.USER_AUTH_CREDENTIALS;
            var refreshToken = token.refresh_token ? token.refresh_token : token;
            var data = new FormData();
            data.append('refresh_token', refreshToken);
            authorisation(data, 'refresh_token', authorization)
                .success(function (data, status, headers, config) {
                    data.authorization = authorization;
                    setToken(data);
                    if (successCallback) {
                        successCallback(data);
                    }
                })
                .error(function (response, status) {
                    deleteToken()
                    deleteAdminToken()
                    if (errorCallback) {
                        errorCallback(response);
                    }
                });
        };

        let authorisation = (request, grantType, authorization) => {
            return $http.post('/api/v1/oauth2/token?scope=back_office&grant_type=' + grantType,
                request, getAuthConfig(authorization))
        }

        let isAbsoluteUrl = function (url) {
            let pat = /^https?:\/\//i;
            return pat.test(url);
        };

        let logout = function (isNonRedirect, callback) {
            let fullLogoutCallback = () => {
                deleteToken();
                deleteAdminToken();
                $wsService.closeAll();

                user = null;
                if (isNonRedirect !== true) {
                    $timeout(function () {
                        $location.path('/');
                    }, 0);
                }
                if (callback) {
                    callback();
                }
            }

            let logoutCallback = function () {
                if (hasAdminToken()) {
                    let adminToken = getAdminToken();
                    let revokeRequest = new FormData();
                    revokeRequest.append('token', adminToken.refresh_token);
                    $http.post('/api/v1/oauth2/revoke', revokeRequest, getAuthConfig(adminToken.authorization))
                        .success(fullLogoutCallback)
                        .error(fullLogoutCallback);
                } else {
                    fullLogoutCallback()
                }
            };

            let token = getToken();
            if (token) {
                let revokeRequest = new FormData();
                revokeRequest.append('token', token.refresh_token);
                $http.post('/api/v1/oauth2/revoke', revokeRequest, getAuthConfig(token.authorization))
                    .success(logoutCallback)
                    .error(logoutCallback);
            } else {
                fullLogoutCallback()
            }
        };

        let getAuthConfig = (authorization) => {
            return {
                headers: {
                    'Content-Type': undefined,
                    'Authorization': authorization
                }
            };
        }

        let logoutToAdmin = function (redirectPath) {
            let logoutToAdminCallback = () => {
                restoreAndDeleteBackupToken(() => {
                    user = null;
                    if (redirectPath) {
                        $location.url(redirectPath);
                    } else {
                        $location.url("/dashboard");
                    }
                });
            }

            const token = getToken();
            let revokeRequest = new FormData();
            revokeRequest.append('token', token.refresh_token);
            $http.post('/api/v1/oauth2/revoke', revokeRequest, getAuthConfig(token.authorization))
                .success(logoutToAdminCallback)
                .error(logoutToAdminCallback);
            $wsService.closeAll();
        };

        let signUp = function (signUp, scope, successCallback, errorCallback) {
            let errorHandler = function (error) {
                ErrorHandler.onError(error);
                delete scope.registration.user.repeatPassword;
                delete scope.registration.user.password;
                scope.registration.disabledUser = false;
                if (errorCallback) {
                    errorCallback(error);
                }
            };

            RecaptchaService.execute('sign_up', function (token) {
                let user = {
                    name: scope.registration.user.name,
                    surname: scope.registration.user.surname,
                    email: scope.registration.user.email,
                    phone: scope.registration.user.phone,
                    password: scope.registration.user.password,
                    gRecaptchaResponse: token,
                    gRecaptchaAction: "sign_up"
                };
                signUp(user, function (response) {
                    authorizeUser(response.token, (data) => {
                        setToken(data);
                        currentUser(function (user) {
                            if (isPartiallyRegistered()) {
                                $location.path('/complete-social-registration');
                            } else if (isUser() && user.activated && $requestStorage.hasDraftRequest()) {
                                NotificationWSService.connect(getToken());
                                $location.path('/create-request').search({draft: true});
                            } else if (isUser() && $requestStorage.hasDraftedBike()) {
                                NotificationWSService.connect(getToken());
                                $location.path('/add-bike').search({draft: true});
                            } else if (isUser() && !user.activated && $requestStorage.hasDraftRequest()) {
                                $location.path('/complete-creation-request').search({email: user.email});
                            } else if (isUser() && !user.activated) {
                                Notification.warning('Go to your mailbox to activate this account');
                                NotificationWSService.connect(getToken());
                                $location.path('/dashboard');
                            } else {
                                NotificationWSService.connect(getToken());
                                $location.path('/dashboard');
                            }
                        }, true);
                    });
                    scope.registration.disabledUser = false;
                    if (successCallback) {
                        successCallback(response);
                    }
                }, errorHandler);
            }, errorHandler);
        };

        let joinVelotooler = function (signUp, scope, successCallback, errorCallback) {
            RecaptchaService.execute('sign_up', function (token) {
                let user = {
                    name: scope.registration.mechanic.name,
                    surname: scope.registration.mechanic.surname,
                    address: {address: scope.registration.mechanic.address},
                    phone: scope.registration.mechanic.phone,
                    email: scope.registration.mechanic.email,
                    password: scope.registration.mechanic.password,
                    mechanicCategory: scope.registration.mechanic.mechanicCategory,
                    gRecaptchaResponse: token,
                    gRecaptchaAction: "sign_up"
                };
                signUp(user, function (response) {
                    authorizeUser(response.token, (data) => {
                        setToken(data);
                        currentUser(function (user) {
                            if (isPartiallyRegistered()) {
                                $location.path('/complete-social-registration');
                            } else if (!user.activated) {
                                Notification.warning('Go to your mailbox to activate this account');
                                NotificationWSService.connect(getToken());
                                $location.path('/dashboard');
                            } else {
                                NotificationWSService.connect(getToken());
                                $location.path('/dashboard');
                            }
                        }, true);
                    });
                    scope.registration.disabledMechanic = false;
                    if (successCallback) {
                        successCallback(response);
                    }
                }, function (error) {
                    ErrorHandler.onError(error);
                    delete scope.registration.mechanic.repeatPassword;
                    delete scope.registration.mechanic.password;
                    scope.registration.disabledMechanic = false;
                    if (errorCallback) {
                        errorCallback(error);
                    }
                });
            });
        };

        let completeSocialRegistration = (confirmRegistration, code, data, callback) => {
            confirmRegistration({token: code}, data, function () {
                authorizeUser(code, null, () => {
                    if (callback) {
                        callback()
                    }
                });
            });
        }

        let refreshAuthentication = function () {
            let deferred = $q.defer();
            let currentToken = getToken();
            let newToken = $location.search().accessToken || currentToken;
            $location.search('accessToken', null);
            if (newToken) {
                setToken(newToken);
            }
            currentUser(function (user) {
                deferred.resolve(user);
            }, newToken && newToken !== currentToken);
            return deferred.promise;
        };

        let updateUser = function (data) {
            user.name = user.name || "";
            user.surname = user.surname || "";
            for (let p in data) {
                if (p in user) {
                    user[p] = data[p];
                }
            }
        };

        let isLogin = function () {
            if (getToken()) {
                return true;
            }
            return false;
        };

        let isAdmin = function () {
            return isLogin() && user && user.role === 'ADMIN';
        };

        let isMechanic = function () {
            return isLogin() && user && user.role === 'MECHANIC';
        };

        let isVerifiedMechanic = function () {
            return isMechanic() && (user.verified === true);
        };

        let isAcceptAgreement = function () {
            return isVerifiedMechanic() && user.acceptAgreement === true;
        };

        let isProbationaryMechanic = function () {
            return isVerifiedMechanic() && user.verificationLevel === 'PROBATIONARY';
        };

        let isDealer = function () {
            return isMechanic() && user.dealer === true;
        };

        let isFullyVerifiedMechanic = function () {
            return isVerifiedMechanic() && user.verificationLevel === 'FULL';
        };

        let isNotVerifiedMechanic = function () {
            return isMechanic() && user.verified === false;
        };

        let isUser = function () {
            return isLogin() && user && user.role === 'USER';
        };

        let isActivatedUser = function () {
            return (isUser() && user.activated) || isLogoutToAdminAvailable();
        };

        let isPartiallyRegistered = function () {
            return isLogin() && user && user.role === 'PARTIALLY_REGISTERED';
        };

        let isFullyLoggedIn = function () {
            return isLogin() && user && user.role !== 'PARTIALLY_REGISTERED';
        };

        let isBikeBusinessSupervisor = function () {
            return isLogin() && user && user.role === 'BIKE_BUSINESS_SUPERVISOR';
        };

        let isBikeOrganisationOwner = function () {
            return isLogin() && user && user.role === 'BIKE_ORGANIZATION_OWNER';
        };

        let isBusinessAccount = function () {
            return isBikeBusinessSupervisor() || isBikeOrganisationOwner();
        };

        let isLogoutToAdminAvailable = function () {
            return !!getAdminToken();
        };

        let getToken = function () {
            let token = $localStorage.get('x-token');

            if (!token) {
                token = $cookies.get('x-token');
            }

            return token;
        };

        let getAdminToken = function () {
            let token = $localStorage.get('x-admin-token');

            if (!token) {
                token = $cookies.get('x-admin-token');
            }

            return token;
        };

        let hasAdminToken = function () {
            let adminToken = getAdminToken();

            return !!adminToken
        }

        let setToken = function (token) {
            try {
                $localStorage.tryToSet('x-token', token);
            } catch (e) {
                $cookies.put('x-token', token);
            }
            updateHttp(token);

            return token;
        };

        let setAdminToken = function (token) {
            try {
                $localStorage.tryToSet('x-admin-token', token);
            } catch (e) {
                $cookies.put('x-admin-token', token);
            }

            return token;
        };

        let backupAndDeleteToken = function (callback) {
            let token = getToken();
            setAdminToken(token);
            deleteToken();
            user = null;
            if (callback) {
                callback();
            }
        };

        let restoreAndDeleteBackupToken = function (callback) {
            let token = getAdminToken();
            setToken(token);
            deleteAdminToken();
            user = null;
            if (callback) {
                callback();
            }
        };

        let deleteToken = function () {
            try {
                $localStorage.tryToRemove('x-token');
                $cookies.remove('x-token');
            } catch (e) {
                $cookies.remove('x-token');
            }

            updateHttp(undefined);
        };

        let deleteAdminToken = function () {
            try {
                $localStorage.tryToRemove('x-admin-token');
                $cookies.remove('x-admin-token');
            } catch (e) {
                $cookies.remove('x-admin-token');
            }
        };

        let updateHttp = function (token) {
            if (token) {
                $http.defaults.headers.common['Authorization'] = token.token_type + ' ' + token.access_token;
            } else {
                delete $http.defaults.headers.common['Authorization'];
            }
        };

        let activeMechanic = function () {
            return isMechanic() && user.active;
        };

        let api = () => {
            if (isMechanic()) {
                return 'mechanic';
            }

            if (isAdmin()) {
                return 'admin';
            }

            if (isBusinessAccount()) {
                return 'partner';
            }

            if (isLogin()) {
                return 'customer';
            }

            return 'public';
        };

        return {
            activation: activation,
            cancelActivation: cancelActivation,
            activateBusinessAccount: activateBusinessAccount,
            login: login,
            refreshToken: refreshToken,
            logout: logout,
            signUp: signUp,
            joinVelotooler: joinVelotooler,
            completeSocialRegistration: completeSocialRegistration,
            currentUser: currentUser,
            updateUser: updateUser,
            user: function () {
                return user;
            },
            isLogin: isLogin,
            isUser: isUser,
            isActivatedUser: isActivatedUser,
            isAdmin: isAdmin,
            isMechanic: isMechanic,
            isPartiallyRegistered: isPartiallyRegistered,
            isVerifiedMechanic: isVerifiedMechanic,
            isNotVerifiedMechanic: isNotVerifiedMechanic,
            isProbationaryMechanic: isProbationaryMechanic,
            isDealer: isDealer,
            isFullyVerifiedMechanic: isFullyVerifiedMechanic,
            isAcceptAgreement: isAcceptAgreement,
            isBikeBusinessSupervisor: isBikeBusinessSupervisor,
            isBikeOrganisationOwner: isBikeOrganisationOwner,
            isBusinessAccount: isBusinessAccount,
            getToken: getToken,
            authorize: authorizeAsUser,
            isFullyLoggedIn: isFullyLoggedIn,
            refreshAuthentication: refreshAuthentication,
            backupAndDeleteToken: backupAndDeleteToken,
            isLogoutToAdminAvailable: isLogoutToAdminAvailable,
            logoutToAdmin: logoutToAdmin,
            activeMechanic: activeMechanic,
            sendActivationEmail: sendActivationEmail,
            api: api,
            hasAdminToken: hasAdminToken
        };
    });
